/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,no-undef */
import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import axios from 'axios';
import SVG from 'react-inlinesvg';
import { useHistory } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, injectIntl, FormattedDate } from 'react-intl';
import * as auth from '../../../../../app/modules/Auth/_redux/authRedux';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { logout } from '../../../../../app/modules/Auth/_redux/authCrud';
import { BACKEND_URL } from '../../../../../config';
import { addComma } from '../../../../../app/utils/comma';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import CountryDialCodePicker from '../../../../../app/components/inputs/CountryDialCodePicker';
import { mapCountryDialCodeToCountryObject } from '../../../../../app/utils/countryService';
import { getErrorPhoneNumber } from '../../../../../app/modules/Auth/pages/validator';
import { useIsWalletActive } from '../../../../../app/hooks/useFeatureFlag';
import Carousel from '../../carousel/Carousel';
import * as actions from '../../../../../app/modules/ECommerce/_redux/groupManage/groupActions';
import penSvg from '../../../../../app/assets/pen.svg';
import ProgressBar from '../../progess-bar/ProgressBar';
import { checkPreview, useCustomTheme } from '../../../../../app/hooks/useCustomTheme';
import { styled as muiStyled } from '@mui/material/styles';

import { alpha } from '@mui/material';
import SubscriptionConfirmModal from '../../modals/SubscriptionConfirmModal';
import DatePicker from '../../../../../app/components/form/DatePicker';
import { CUSTOM_KEYS, useActiveAndRequiredField, useRegisField } from '../../../../../app/hooks/useRegisField';
import Swal from 'sweetalert2';

const Screenmode = {
  UserProfile: 'profile',
  Edit: 'edit',
  ChangePassword: 'password'
};

const QuickUser = props => {
  const history = useHistory();
  const { intl } = props;
  const dispatch = useDispatch();
  const { user, shop, authToken, shopSetting, shopPointandWalletSetting } = useSelector(state => state.auth);
  const activeWallet = useIsWalletActive();
  const { groups } = useSelector(state => state.groups);
  const { active } = useActiveAndRequiredField();

  const regisField = useRegisField();

  const customFields = Object.assign({}, ...CUSTOM_KEYS.map(key => ({ [key]: user?.[key] })));
  const memberExpire = user?.memberExpire;
  const memberExpireSetting = shop?.memberExpire;

  const pointText =
    shopPointandWalletSetting?.point[0][intl.locale.toUpperCase()] || intl.formatMessage({ id: 'menu.point' });
  const walletText =
    shopPointandWalletSetting?.point[1][intl.locale.toUpperCase()] || intl.formatMessage({ id: 'menu.credit' });

  const [changePasswordForm, setChangePasswordForm] = useState({ old_password: '', password: '', re_password: '' });
  const [mode, setMode] = useState(Screenmode.UserProfile);
  const [userProfile, setUserProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    birthdate: '',
    shippingAddress: '',
    remark: '',
    phone: '',
    countryDialCode: null
  });
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);

  const logoutClick = async () => {
    const toggle = document.getElementById('kt_quick_user_toggle');
    if (toggle) {
      toggle.click();
    }
    const loginPath = user.type === 'admin' ? '/admin/login' : `/shop/${user.seqId}/login`;
    await logout();
    props.logout();
    history.push(loginPath);
  };

  const handleClearLocalStorage = () => {
    localStorage.clear();
    window.location.reload();
  };

  useEffect(() => {
    mappingUserProfile();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    dispatch(actions.fetchGroups());
  }, []);

  const mappingUserProfile = () => {
    if (user) {
      setUserProfile({
        ...{
          firstName: user.firstName,
          lastName: user.lastName,
          gender: user.gender,
          email: user.email,
          phone: user.phone,
          birthdate: user.birthdate,
          shippingAddress: user.shippingAddress,
          remark: user.remark,
          countryDialCode: user.countryDialCode
        },
        ...customFields
      });
    }
  };
  const test = async () => {
    await axios.post(`${BACKEND_URL}/announcement/test`, { headers: { Authorization: `Bearer ${authToken}` } });
    Swal.fire({
      icon: 'success',
      text: intl.formatMessage({
        id: 'recheck.notification.alert'
      }),
      timer: 2000,
      showConfirmButton: false
    });
  };

  const onSubmit = async event => {
    event.preventDefault();
    const emailRegex = /^\S+@\S+\.\S+$/;

    if (userProfile.email && !emailRegex.test(userProfile.email)) {
      Swal.fire({
        icon: 'error',
        text: intl.formatMessage({
          id: 'alert.invalid.email.format'
        })
      });

      return;
    }

    if (!userProfile.phone) {
      Swal.fire({
        icon: 'error',
        text: intl.formatMessage({
          id: 'AUTH.VALIDATION.INVALID_PHONE'
        })
      });

      return;
    }

    try {
      const { data } = await axios.patch(`${BACKEND_URL}/customer/${user.id}`, {
        ...userProfile,
        countryDialCode: userProfile.countryDialCode
      });
      if (data.success) {
        Swal.fire({
          icon: 'success',
          text: intl.formatMessage({
            id: 'alert.edit.profile.success'
          })
        });
      }
      //user.point, user.credit from data.data is a field in user not shopUser where the actual points are stored; they must be removed.
      const { point, credit, ...validFieldsInUser } = data.data;

      dispatch({
        type: auth.actionTypes.UserLoaded,
        payload: {
          user: { ...user, ...validFieldsInUser, id: validFieldsInUser._id, seqId: user.seqId, type: user.type }
        }
      });
      setMode(Screenmode.UserProfile);
    } catch (err) {
      console.log(err);
      Swal.fire({
        icon: 'error',
        text: err.response?.data?.message
      });
    }
  };

  const onSubmitChangePassword = async event => {
    event.preventDefault();

    if (changePasswordForm.re_password !== changePasswordForm.password) {
      Swal.fire({
        icon: 'error',
        text: 'Password do not match. Please enter password again.'
      });

      setChangePasswordForm({ old_password: '', password: '', re_password: '' });
      return;
    }

    if (changePasswordForm.re_password.length < 6 || changePasswordForm.password.length < 6) {
      Swal.fire({
        icon: 'error',
        text: 'Password must have at least 6 characters.'
      });

      setChangePasswordForm({ old_password: '', password: '', re_password: '' });
      return;
    }

    try {
      const { data } = await axios.patch(`${BACKEND_URL}/customer/${user.id}`, changePasswordForm);
      if (data.success) {
        Swal.fire({
          icon: 'success',
          text: 'Change password success.'
        });

        dispatch({
          type: auth.actionTypes.UserLoaded,
          payload: { user: { ...data.data, id: data.data._id, seqId: user.seqId, type: user.type } }
        });
        setChangePasswordForm({ old_password: '', password: '', re_password: '' });
        setMode(Screenmode.UserProfile);
      } else {
        Swal.fire({
          icon: 'error',
          text: data.message
        });

        setChangePasswordForm({ old_password: '', password: '', re_password: '' });
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: error.response.data.msg || error.response.data.message
      });
      setChangePasswordForm({ old_password: '', password: '', re_password: '' });
    }
  };

  const onCloseButton = () => {
    setMode(Screenmode.UserProfile);
    mappingUserProfile();
  };

  const onChange = e => {
    setUserProfile({ ...userProfile, [e.target.name]: e.target.value });
  };

  const onDateChange = date => {
    setUserProfile({ ...userProfile, birthdate: new Date(date) });
  };

  const handleChangeRoleToCustomer = async () => {
    // if (window.sessionStorage.getItem('THEME_PREVIEW') || window.sessionStorage.getItem('IMG_PREVIEW')) {
    //   window.sessionstorage.clear()
    //   window.location.reload(false);
    // }

    // window.sessionStorage.clear();
    // window.location.reload(false);

    try {
      if (user.type !== 'customer') {
        const { data } = await axios.put(`${BACKEND_URL}/user/role`, { id: user.shopUserId, role: 'customer' });
        if (data.success) {
          dispatch({
            type: auth.actionTypes.UserLoaded,
            payload: { user: { ...user, type: 'customer', demoRole: user.type, loggedInBranch: data.newBranch } }
          });
          Swal.fire({
            icon: 'success',
            text: intl.formatMessage({ id: 'alert.change.role.success' })
          });
        }
      } else {
        const { data } = await axios.put(`${BACKEND_URL}/user/role`, { id: user.shopUserId, role: user.demoRole });
        if (data.success) {
          dispatch({
            type: auth.actionTypes.UserLoaded,
            payload: { user: { ...user, type: user.demoRole, demoRole: user.demoRole, loggedInBranch: data.newBranch } }
          });
          Swal.fire({
            icon: 'success',
            text: intl.formatMessage({ id: 'alert.change.role.success' })
          });
        }
      }
    } catch (e) {
      Swal.fire({
        icon: 'error',
        text: 'Something went wrong, Please contact admin.'
      });
    }
  };

  const handleToggleSubscription = async subscribed => {
    const res = await axios.put(`${BACKEND_URL}/shopUser/setting`, {
      isUnsubscribed: !subscribed
    });

    dispatch({
      type: auth.actionTypes.UserLoaded,
      payload: {
        user: { ...user, setting: res.data.data }
      }
    });
  };

  const handleConfirmUnsubscription = async () => {
    await handleToggleSubscription(false);
    setConfirmIsOpen(false);
  };

  const handleClickSubscription = e => {
    if (!e.target.checked) {
      setConfirmIsOpen(true);
    } else {
      handleToggleSubscription(true);
    }
  };

  const customTheme = useCustomTheme();
  const style = customTheme?.profile;
  const existsPreview = checkPreview();

  const StyledSwitch = useMemo(() => {
    return muiStyled(Switch)({
      '& .MuiSwitch-switchBase.Mui-checked': {
        color: style.demoRoleColor,
        '&:hover': {
          backgroundColor: alpha(style.demoRoleColor)
        }
      },
      '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: style.demoRoleColor
      },
      '& .MuiSwitch-thumb': {
        backgroundColor: style.demoRoleColor
      }
    });
  }, [style.demoRoleColor]);

  return (
    <div
      id="kt_quick_user"
      className="offcanvas offcanvas-right offcanvas p-10"
      style={{ background: style.bgColor, color: style.color }}
    >
      <div className="offcanvas-header d-flex align-items-center justify-content-between pb-2">
        <h3 className="font-weight-bold m-0">
          {mode === Screenmode.UserProfile && intl.formatMessage({ id: 'user.profile' })}
          {mode === Screenmode.Edit && intl.formatMessage({ id: 'edit.your.profile' })}
          {mode === Screenmode.ChangePassword && intl.formatMessage({ id: 'change.password' })}
        </h3>
        <a
          href="#"
          className="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
          onClick={() => onCloseButton()}
        >
          <i className="ki ki-close icon-xs text-muted" />
        </a>
      </div>
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={mode}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}
          classNames="fade"
        >
          <div>
            {user && mode === Screenmode.UserProfile && (
              <div className="offcanvas-content pr-5 mr-n5 fade-user pt-5">
                <div className="d-flex align-items-center mt-1 justify-content-between">
                  <div className="symbol symbol-100 mr-5 symbol-light-success">
                    <div className="d-flex justify-content-between navi-link mb-4">
                      <div className="navi-text">
                        {user.memberGroups?.length > 0 ? (
                          <>
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {intl.formatMessage({ id: 'menu.member.group' })}
                            </div>
                            {user.memberGroups.map(group => (
                              <div key={group._id} style={{ color: style.color }}>
                                {group.name}
                              </div>
                            ))}
                          </>
                        ) : (
                          <div style={{ color: style.color }} className={`font-weight-bold`}>
                            {intl.formatMessage({ id: 'menu.member.group' })} <br />
                            {intl.formatMessage({ id: 'menu.no.group' })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {memberExpireSetting?.isExpireMember?.isEnable && memberExpire?.isEnable ? (
                    <div className="navi-link mb-4">
                      <div className="navi-text">
                        <div style={{ color: style.color }} className={`font-weight-bold`}>
                          {intl.formatMessage({ id: 'member.expiration.date' })}
                        </div>
                        {memberExpire.expireDate ? (
                          <div style={dateStyle}>
                            <FormattedDate value={memberExpire.expireDate} day="2-digit" month="long" year="numeric" />
                          </div>
                        ) : (
                          '-'
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div style={{ color: style.color }} className={`font-weight-bold d-flex flex-column`}>
                        <div>{intl.formatMessage({ id: 'member.expiration.date' })}</div>
                        <div className="d-flex justify-content-center">{' -'}</div>
                      </div>
                    </>
                  )}
                </div>

                {user.memberGroups?.length > 0 && groups && <Carousel groups={groups} user={user} />}

                <div className="navi navi-spacer-x-0 pl-3" style={{ paddingTop: '8px' }}>
                  <style>{gridStyle}</style>

                  <div className="d-flex justify-content-end">
                    {(user && user.type === 'customer' ? shop?.customerCanEditCustomer ?? true : true) && (
                      <span className="svg-icon-lg svg-icon-primary">
                        <SVG src={penSvg} onClick={() => setMode(Screenmode.Edit)}></SVG>
                      </span>
                    )}
                  </div>

                  <div className="grid-container">
                    {user.firstName && (
                      <div className="grid-item">
                        <div className="navi-link mb-4">
                          <div className="navi-text">
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {intl.formatMessage({ id: 'user.firstname' })}
                            </div>
                            <div style={{ color: style.color }}>{user.firstName}</div>
                          </div>
                        </div>
                      </div>
                    )}

                    {user.lastName && (
                      <div className="grid-item">
                        <div className="navi-link mb-4">
                          <div className="navi-text">
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {intl.formatMessage({ id: 'user.lastname' })}
                            </div>
                            <div style={{ color: style.color }}>{user.lastName}</div>
                          </div>
                        </div>
                      </div>
                    )}

                    {user.gender && (
                      <div className="grid-item">
                        <div className="navi-link mb-4">
                          <div className="navi-text">
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {intl.formatMessage({ id: 'user.gender' })}
                            </div>
                            <div style={{ color: style.color }}>
                              {user?.gender === 'male'
                                ? intl.formatMessage({ id: 'gender.male' })
                                : user?.gender === 'female'
                                ? intl.formatMessage({ id: 'gender.female' })
                                : user?.gender === 'lgbtq+'
                                ? intl.formatMessage({ id: 'gender.lgbtq+' })
                                : intl.formatMessage({ id: 'gender.not.specified' })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {user.phone && (
                      <div className="grid-item">
                        <div className="navi-link mb-4">
                          <div className="navi-text">
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {intl.formatMessage({ id: 'user.mobile' })}
                            </div>
                            <div style={{ color: style.color }}>{user.phone}</div>
                          </div>
                        </div>
                      </div>
                    )}

                    {user.email && (
                      <div className="grid-item">
                        <div className="navi-link mb-4">
                          <div className="navi-text">
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {intl.formatMessage({ id: 'user.email' })}
                            </div>
                            <div style={{ color: style.color }}>{user.email}</div>
                          </div>
                        </div>
                      </div>
                    )}

                    {user.birthdate && (
                      <div className="grid-item">
                        <div className="navi-link mb-4">
                          <div className="navi-text">
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {intl.formatMessage({ id: 'user.birthdate' })}
                            </div>
                            <div style={{ color: style.color }}>
                              {user.birthdate ? (
                                <FormattedDate value={user.birthdate} day="2-digit" month="long" year="numeric" />
                              ) : (
                                '-'
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="grid-item">
                      <div className="navi-link mb-4">
                        <div className="navi-text">
                          <div style={{ color: style.color }} className={`font-weight-bold`}>
                            {pointText}
                          </div>
                          <div style={{ color: style.color }}>{addComma(user.expirePoint + user.point)}</div>
                        </div>
                      </div>
                    </div>

                    {activeWallet && (
                      <div className="grid-item">
                        <div className="navi-link mb-4">
                          <div className="navi-text">
                            <div style={{ color: style.color }} className={`font-weight-bold`}>
                              {walletText}
                            </div>
                            <div style={{ color: style.color }}>{addComma(user.expireWallet + user.credit)}</div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/*new*/}

                    {CUSTOM_KEYS.map(
                      (key, idx) =>
                        active?.[key] && (
                          <div className="grid-item">
                            <div className="navi-link mb-4">
                              <div className="navi-text">
                                <div style={{ color: style.color }} className={`font-weight-bold`}>
                                  {regisField?.customFields?.[idx]?.name}
                                </div>
                                <div style={{ color: style.color }}>{user?.[key] || '-'}</div>
                              </div>
                            </div>
                          </div>
                        )
                    )}
                  </div>

                  <div className="separator separator-solid mt-4 mb-5" style={{ borderColor: '#C4C4C4' }} />

                  {groups && <ProgressBar groups={groups} user={user} intl={intl} />}

                  <div className="d-flex justify-content-between">
                    {shop && user && shop.isDemo && (user.type !== 'customer' || user.demoRole !== 'customer') ? (
                      <div>
                        <div style={{ color: style.color }} className={`font-weight-bold`}>
                          {intl.formatMessage({ id: 'user.change.to.customer' })}
                        </div>
                        <FormControlLabel
                          control={
                            <StyledSwitch
                              checked={user.type === 'customer'}
                              onClick={() => handleChangeRoleToCustomer()}
                            />
                          }
                        />
                      </div>
                    ) : null}

                    {(shop?.disableSubscription || (!shop?.disableSubscription && user.setting?.isUnsubscribed)) && (
                      <div>
                        <div style={{ color: style.color }} className={`font-weight-bold`}>
                          {intl.formatMessage({ id: 'user.subscription' })}
                        </div>
                        <FormControlLabel
                          control={
                            <StyledSwitch checked={!user.setting?.isUnsubscribed} onClick={handleClickSubscription} />
                          }
                        />
                      </div>
                    )}
                  </div>
                  <SubscriptionConfirmModal
                    open={confirmIsOpen}
                    onConfirm={() => handleConfirmUnsubscription()}
                    onClose={() => setConfirmIsOpen(false)}
                  />

                  <div className="d-flex justify-content-around my-3 ">
                    <button
                      style={{ background: style.clearBgColor, color: style.clearColor }}
                      className="btn  btn-bold mb-3 px-4"
                      onClick={handleClearLocalStorage}
                    >
                      {intl.formatMessage({ id: 'clear.website.data' })}
                    </button>

                    <button
                      style={{ background: style.logoutBgColor, color: style.logoutColor }}
                      className="btn  btn-bold mb-3 px-4"
                      onClick={logoutClick}
                    >
                      {intl.formatMessage({ id: 'sign.out' })}
                    </button>
                  </div>

                  {/* {user.shippingAddress && <div className="navi-link mb-4">
                    <div className="navi-text">
                      <div className="text-muted font-weight-bold">
                        {intl.formatMessage({ id: 'user.shipping.address' })}
                      </div>
                      <div style={{ color: '#B5B5C3' }}>{user.shippingAddress}</div>
                    </div>
                  </div>}
                  {user.remark && <div className="navi-link mb-4">
                    <div className="navi-text">
                      <div className="text-muted font-weight-bold">
                        {intl.formatMessage({ id: 'user.remark' })}
                      </div>
                      <div style={{ color: '#B5B5C3' }}>{user.remark}</div>
                    </div>
                  </div>} */}

                  {shop && user.type === 'customer' ? null : (
                    <div
                      className="separator separator-solid my-7"
                      style={{ borderWidth: '2px', borderColor: '#C4C4C4' }}
                    />
                  )}

                  <div className="d-flex justify-content-center" style={{ marginTop: `10px` }}>
                    {user && user.type !== 'customer' && (
                      <button
                        style={{ background: style.notifyBgColor, color: style.notifyColor }}
                        className="btn btn-bold"
                        onClick={() => {
                          test();
                        }}
                      >
                        {intl.formatMessage({ id: 'recheck.notification' })}
                      </button>
                    )}
                  </div>

                  <div className="d-flex justify-content-center" style={{ marginTop: `10px` }}>
                    {existsPreview && (
                      <button
                        // style={{ background: style.notifyBgColor, color: style.notifyColor }}
                        className="btn btn-primary btn-bold"
                        onClick={() => {
                          // window.sessionStorage.clear();
                          sessionStorage.removeItem('THEME_NAVBAR_PREVIEW');
                          sessionStorage.removeItem('THEME_SIDEBAR_PREVIEW');
                          sessionStorage.removeItem('THEME_PROFILE_PREVIEW');
                          sessionStorage.removeItem('THEME_MAINHEADER_PREVIEW');
                          sessionStorage.removeItem('THEME_MAIN_PREVIEW');
                          sessionStorage.removeItem('THEME_REGIS_PREVIEW');
                          window.location.reload();
                        }}
                      >
                        {intl.formatMessage({ id: 'clear.preview' })}
                      </button>
                    )}
                  </div>

                  {shop && user.type !== 'customer' ? (
                    <ExpiredDateContainer style={{ background: style.expireBgColor, color: style.expireColor }}>
                      <DateLabelStyled>
                        {intl.formatMessage({ id: 'create.shop.date' })}:{' '}
                        {shop.createdDate ? moment(shop.createdDate).format('DD/MM/YYYY') : '-'}
                      </DateLabelStyled>
                      <DateLabelStyled>
                        {intl.formatMessage({ id: 'exipred.shop.date' })}:{' '}
                        {shop.expiryDate ? moment(shop.expiryDate).format('DD/MM/YYYY') : '-'}
                      </DateLabelStyled>
                    </ExpiredDateContainer>
                  ) : null}
                </div>
              </div>
            )}
            {user && mode === Screenmode.Edit && (
              <div className="offcanvas-content pr-5 mr-n5 fade-user" style={{ opacity: 1, transition: '700ms' }}>
                <form onSubmit={e => onSubmit(e)}>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.firstname' })}</div>
                    <input
                      className="form-control"
                      name="firstName"
                      value={userProfile.firstName}
                      onChange={e => onChange(e)}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.lastname' })}</div>
                    <input
                      className="form-control"
                      name="lastName"
                      value={userProfile.lastName}
                      onChange={e => onChange(e)}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.email' })}</div>
                    <input
                      className="form-control"
                      name="email"
                      value={userProfile.email}
                      onChange={e => onChange(e)}
                    />
                  </div>

                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.birthdate' })}</div>

                    <DatePicker
                      InputProps={{
                        disableUnderline: true
                      }}
                      value={userProfile.birthdate}
                      onChange={date => onDateChange(date)}
                    />
                  </div>

                  {CUSTOM_KEYS.map(
                    (key, idx) =>
                      active?.[key] && (
                        <div className="form-group mb-4">
                          {console.log(regisField?.customFields?.[idx]?.name)}
                          <div className="text-muted font-weight-bold">{regisField?.customFields?.[idx]?.name}</div>
                          <input
                            className="form-control"
                            name={key}
                            value={userProfile?.[key]}
                            onChange={e => onChange(e)}
                          />
                        </div>
                      )
                  )}

                  {/* <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">
                      {intl.formatMessage({ id: 'user.shipping.address' })}
                    </div>
                    <textarea className="form-control" rows="3" name="shippingAddress" value={userProfile.shippingAddress} onChange={(e) => onChange(e)} />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">
                      {intl.formatMessage({ id: 'user.remark' })}
                    </div>
                    <textarea className="form-control" rows="3" name="remark" value={userProfile.remark} onChange={(e) => onChange(e)} />
                  </div> */}
                  <div className="form-group">
                    <CountryDialCodePicker
                      intl={intl}
                      countryDialCode={userProfile.countryDialCode}
                      formWrapperStyle={{ marginLeft: 0 }}
                      labelClass="text-muted font-weight-bold"
                      setCountryDialCode={newVal => setUserProfile(prev => ({ ...prev, countryDialCode: newVal }))}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.phone' })}</div>
                    <input
                      className="form-control"
                      name="phone"
                      value={userProfile.phone}
                      onChange={e => onChange(e)}
                    />
                  </div>
                  <div className="d-flex justify-content-around mt-5 pt-5">
                    <button
                      type="button"
                      className="btn btn-light btn-bold"
                      onClick={() => {
                        setMode(Screenmode.UserProfile);
                        mappingUserProfile();
                      }}
                    >
                      {intl.formatMessage({ id: 'button.cancel' })}
                    </button>
                    <button type="submit" className="btn btn-bold btn-primary">
                      {intl.formatMessage({ id: 'button.submit' })}
                    </button>
                  </div>
                </form>
              </div>
            )}
            {user && mode === Screenmode.ChangePassword && (
              <div className="offcanvas-content pr-5 mr-n5 fade-user" style={{ opacity: 1, transition: '700ms' }}>
                <form onSubmit={e => onSubmitChangePassword(e)}>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">
                      <FormattedMessage id="USER_OLD_PASSWORD" />
                    </div>
                    <input
                      type="password"
                      className="form-control"
                      name="old_password"
                      value={changePasswordForm.old_password}
                      onChange={e => setChangePasswordForm({ ...changePasswordForm, old_password: e.target.value })}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.password' })}</div>
                    <input
                      type="password"
                      className="form-control"
                      name="password"
                      value={changePasswordForm.password}
                      onChange={e => setChangePasswordForm({ ...changePasswordForm, password: e.target.value })}
                    />
                  </div>
                  <div className="form-group mb-4">
                    <div className="text-muted font-weight-bold">{intl.formatMessage({ id: 'user.repassword' })}</div>
                    <input
                      type="password"
                      className="form-control"
                      name="re_password"
                      value={changePasswordForm.re_password}
                      onChange={e => setChangePasswordForm({ ...changePasswordForm, re_password: e.target.value })}
                    />
                  </div>
                  <div className="d-flex justify-content-around mt-5 pt-5">
                    <button
                      type="button"
                      className="btn btn-light btn-bold"
                      onClick={() => {
                        setMode(Screenmode.UserProfile);
                        mappingUserProfile();
                      }}
                    >
                      {intl.formatMessage({ id: 'button.cancel' })}
                    </button>
                    <button type="submit" className="btn btn-bold purple-button">
                      {intl.formatMessage({ id: 'button.submit' })}
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default injectIntl(connect(null, auth.actions)(QuickUser));

const ExpiredDateContainer = styled.div`
  // background-color: #17b987;
  // color: #000;
  margin: 20px 15px 20px 15px;
  padding: 10px 0 5px 0;
  text-align: center;
  border-radius: 15px;
  box-shadow: 5px 5px 10px rgb(0 0 0 / 20%);
`;

const DateLabelStyled = styled.label`
  margin-bottom: 5px;
  font-weight: bold;
`;

const dateStyle = {
  color: 'red',
  fontWeight: 'bold'
};

const gridStyle = `
.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
}
.grid-item .navi-text {
  word-wrap: break-word;
  word-break: break-word;
`;
