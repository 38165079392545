import { createSlice } from '@reduxjs/toolkit';

const initialItemsState = {
  product: [],
  promotion: [],
  coupon: [],
  specialPackage: [],
  advertisement: [],
  branches: [],
  group: null
};

export const ItemsSlice = createSlice({
  name: 'items',
  initialState: initialItemsState,
  reducers: {
    fetchItemsList: (state, action) => {
      state.product = action.payload.items.product;
      state.promotion = action.payload.items.promotion;
      state.coupon = action.payload.items.coupon;
      state.specialPackage = action.payload.items.specialPackage;
      state.advertisement = action.payload.items.advertisement;
    },
    fetchItemsListForFunsharing: (state, action) => {
      state.promotion = action.payload.items.promotion;
      state.coupon = action.payload.items.coupon;
    },
    fetchItemsListForFunsharingHistory: (state, action) => {
      state.promotion = action.payload.items.promotion;
      state.coupon = action.payload.items.coupon;
    },
    fetchItemsListForMGMHistory: (state, action) => {
      state.promotion = action.payload.items.promotion;
      state.coupon = action.payload.items.coupon;
    },
    fetchItemsListForMGMHistory: (state, action) => {
      state.promotion = action.payload.items.promotion;
      state.coupon = action.payload.items.coupon;
    },
    fetchItemsListForMGMHistory: (state, action) => {
      state.promotion = action.payload.items.promotion;
      state.coupon = action.payload.items.coupon;
    },
    fetchItemsListForMGMHistory: (state, action) => {
      state.promotion = action.payload.items.promotion;
      state.coupon = action.payload.items.coupon;
    },
    fetchGroupList: (state, action) => {
      state.groups = action.payload.groups;
    },
    fetchBranchList: (state, action) => {
      state.branches = action.payload.branches;
    },
    branchListFetched: (state, action) => {
      state.branches = action.payload.branches;
    }
  }
});
